import styles from './styles.module.scss';
import { FC } from 'react';
import { compareSnippetWithTextArray } from '../utils/misc';

interface Props {
  data: { raw: string | string[]; snippet: string };
}

export const OwnProducts: FC<Props> = ({ data }) => {
  const textArray = compareSnippetWithTextArray({
    text: data.raw,
    snippet: data.snippet,
  });

  return (
    <dl className={styles.row}>
      <dt>Собственные продукты</dt>
      <dd>
        {textArray.map((item, index) => (
          <div
            key={index}
            className={styles.badge}
            dangerouslySetInnerHTML={{
              __html: item,
            }}
          />
        ))}
      </dd>
    </dl>
  );
};
