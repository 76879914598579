import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from 'react';
import { useUploadImage } from '../../requests/app/useUploadImage';
import { Loader } from '../../../common/shared/Loader';
import { InputButton } from '../../shared/InputButton';
import { ImageType, ReplaceImageNameRequest } from '../../requests/app/types';
import styles from './styles.module.scss';

interface UploadImageProps {
  imageType: ImageType;
  setImageName: Dispatch<SetStateAction<string>>;
  itemId?: string;
  replaceImageName?: (data: ReplaceImageNameRequest) => void;
  refetch?: () => Promise<any>;
}

export const UploadImage: FC<UploadImageProps> = ({
  imageType,
  setImageName,
  itemId,
  replaceImageName,
  refetch,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { uploadImage } = useUploadImage();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(e.target?.files?.[0] || null);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append('image', selectedFile);

      const uploadResult = await uploadImage({
        formData,
        imageType,
      });
      const newImageName = uploadResult?.data;

      if (itemId && newImageName && replaceImageName && refetch) {
        await replaceImageName({
          itemId,
          name: newImageName,
        });
        await refetch();
        setImageName(newImageName);
      }

      if (newImageName) {
        setImageName(newImageName);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Loader visible={loading} />
      <div className={styles.wrapper}>
        <input type="file" accept="image/*" onChange={handleFileChange} />
        <InputButton
          className={styles.uploadButton}
          text="Upload image"
          handler={handleUpload}
        />
      </div>
    </>
  );
};
