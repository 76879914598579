export const getYyyyMmDd = (timestamp: number): string => {
  const rawDate = new Date(timestamp);
  return new Intl.DateTimeFormat('ru').format(rawDate);
};

export const reverseDate = (date: string): string => {
  const splitted = date.split('-');
  return `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
};

export const getTimestampFromDate = (date: string) => {
  const splitted = date.split('-');
  const year = Number(splitted[0]);
  const month = Number(splitted[1]) - 1;
  const day = Number(splitted[2]) + 1;
  const formattedDate = new Date(year, month, day);
  return formattedDate.getTime();
};
