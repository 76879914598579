import { UploadImageRequest } from './types';
import { ResponseCustom } from '../types';
import { API } from '../constants';

export const uploadImage = async ({
  formData,
  imageType,
  token,
}: UploadImageRequest): Promise<ResponseCustom<string>> => {
  const response = await fetch(
    `${API}/upload-image?token=${token}&type=${imageType}`,
    {
      method: 'POST',
      body: formData,
    }
  );
  return await response.json();
};
