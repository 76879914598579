import styles from './styles.module.scss';
import { FC, useMemo, useState } from 'react';
import { compareSnippetWithTextArray } from '../utils/misc';
import cn from 'classnames';
import { usePatchEntitlement } from '../../../requests/users/usePatchEntitlement';
import { useDeleteEntitlement } from '../../../requests/users/useDeleteEntitlement';
import { Modal } from '../../../../common/shared/Modal';
import { ConfirmCard } from '../../ConfirmCard';
import { Loader } from '../../../../common/shared/Loader';

interface Props {
  documentId: string;
  menuIds?: (string | null)[];
  acquiredMenus?: { raw: string[]; snippet: string };
}

export const Entitlements: FC<Props> = ({
  documentId,
  menuIds,
  acquiredMenus,
}) => {
  const [entitlementId, setEntitlementId] = useState<string>('');

  const [showSetModal, setShowSetModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleSetModal = () => {
    setShowSetModal((prev) => !prev);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const { patchEntitlement, loading: loadingPatch } = usePatchEntitlement();
  const { deleteEntitlement, loading: loadingDelete } = useDeleteEntitlement();

  const filteredMenuIds = useMemo(() => {
    const acquiredIds = acquiredMenus?.raw.reduce(
      (acc, item) => ({
        ...acc,
        [item]: item,
      }),
      {} as Record<string, string>
    );

    return menuIds?.filter((menuId) => {
      if (menuId) {
        return !acquiredIds?.[menuId];
      }
    });
  }, [menuIds, acquiredMenus]);

  const acquiredMenuIds = useMemo(() => {
    if (acquiredMenus) {
      return compareSnippetWithTextArray({
        text: acquiredMenus.raw,
        snippet: acquiredMenus.snippet,
      });
    }
  }, [acquiredMenus]);

  const onClickSetMenu = (id: string | null) => {
    if (id) {
      toggleSetModal();
      setEntitlementId(id);
    }
  };

  const onClickDeleteModal = (id: string) => {
    toggleDeleteModal();
    setEntitlementId(id);
  };

  const onSetEntitlement = async () => {
    if (entitlementId) {
      await patchEntitlement({ documentId, entitlementId });
      toggleSetModal();
    }
  };

  const onDeleteEntitlement = async () => {
    if (entitlementId) {
      await deleteEntitlement({ documentId, entitlementId });
      toggleDeleteModal();
    }
  };

  const SetText = (
    <>
      <span className={cn('bold', 'green-font')}>Назначить меню</span>
      <span className={cn('bold')}>{entitlementId}</span>
    </>
  );

  const DeleteText = (
    <>
      <span className={cn('bold', 'alert-font')}>Удалить меню</span>
      <span className={cn('bold')}>{entitlementId}</span>
    </>
  );

  return (
    <>
      <Loader visible={loadingPatch || loadingDelete} />
      <dl className={styles.row}>
        <dt>Меню</dt>
        <dd>
          {!!filteredMenuIds?.length &&
            filteredMenuIds.map((menuId) => (
              <button
                key={menuId}
                className={styles.itemWidget}
                onClick={() => onClickSetMenu(menuId)}
              >
                <span>{menuId}</span>
                <span>|</span>
                <span>➕</span>
              </button>
            ))}
          {!!acquiredMenuIds?.length &&
            acquiredMenuIds.map((menuId) => (
              <button
                key={menuId}
                className={cn(styles.itemWidget, styles.success)}
                onClick={() => onClickDeleteModal(menuId)}
              >
                <span>{menuId}</span>
                <span>|</span>
                <span>➖</span>
              </button>
            ))}
        </dd>
      </dl>
      {showSetModal && (
        <Modal onClose={toggleSetModal}>
          <ConfirmCard
            confirmHandler={onSetEntitlement}
            cancelHandler={toggleSetModal}
            customText={SetText}
          />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal onClose={toggleDeleteModal}>
          <ConfirmCard
            confirmHandler={onDeleteEntitlement}
            cancelHandler={toggleDeleteModal}
            customText={DeleteText}
          />
        </Modal>
      )}
    </>
  );
};
