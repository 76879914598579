import { RecipeCategoriesEnum } from '../types/recipes';

export const IMAGE_ENDPOINT = process.env.REACT_APP_IMAGE_ENDPOINT;
export const RECIPES_IMG_PATH = process.env.REACT_APP_RECIPES_IMG_PATH;
export const MENU_IMG_PATH = process.env.REACT_APP_MENU_IMG_PATH;

export const TENGE_LABEL = '₸';
export const DEFAULT_PRODUCT_CATEGORY = 'fruits';
export const DEFAULT_RECIPE_CATEGORY = RecipeCategoriesEnum.breakfast;

export const BLACK_COLOR = '#343434';
export const LIGHT_COLOR = '#fff';
