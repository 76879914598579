import { UserSearchProps } from '../../../common/types/users';
import { FC } from 'react';
import styles from './styles.module.scss';
import {
  getActivityLevel,
  getChangeWeightTemp,
  getFeedSystem,
  getWaterSystem,
} from '../../ProductsSearch/content';
import { OwnProducts } from './OwnProducts';
import { getYyyyMmDd } from '../../../common/utils/formatDate';
import { getValue } from '../utils/misc';
import { Promocode } from './Promocode';
import { Entitlements } from './Entitlements';

interface Props {
  usersList: UserSearchProps[];
  menuIds?: (string | null)[];
}

export const UsersList: FC<Props> = ({ usersList, menuIds }) => {
  return (
    <ul className={styles.wrapper}>
      {usersList.map((user) => (
        <li key={user._meta.id} className={styles.item}>
          {!!user['profile.ava'] && (
            <div>
              <img
                className={styles.avaImage}
                src={user['profile.ava'].raw}
                alt={user['profile.name']?.raw}
              />
            </div>
          )}
          {!!user.customerId && (
            <dl className={styles.row}>
              <dt>Customer ID</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user.customerId),
                }}
              />
            </dl>
          )}
          {!!user.firstInstallTime?.raw && (
            <dl className={styles.row}>
              <dt>Создано</dt>
              <dd>{getYyyyMmDd(user.firstInstallTime?.raw)}</dd>
            </dl>
          )}
          {!!user['userPersonalData.givenName'] && (
            <dl className={styles.row}>
              <dt>Имя</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['userPersonalData.givenName']),
                }}
              />
            </dl>
          )}
          {!!user['userPersonalData.familyName'] && (
            <dl className={styles.row}>
              <dt>Фамилия</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['userPersonalData.familyName']),
                }}
              />
            </dl>
          )}
          {!!user['userPersonalData.email'] && (
            <dl className={styles.row}>
              <dt>email</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['userPersonalData.email']),
                }}
              />
            </dl>
          )}
          {!!user['userPersonalData.OS'] && (
            <dl className={styles.row}>
              <dt>OS</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['userPersonalData.OS']),
                }}
              />
            </dl>
          )}
          {!!user['profile.name'] && (
            <dl className={styles.row}>
              <dt>Имя в приложении</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['profile.name']),
                }}
              />
            </dl>
          )}
          {!!user['profile.gender'] && (
            <dl className={styles.row}>
              <dt>Пол</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['profile.gender']),
                }}
              />
            </dl>
          )}
          {!!user['profile.age'] && (
            <dl className={styles.row}>
              <dt>Возраст</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['profile.age']),
                }}
              />
            </dl>
          )}
          {!!user['profile.height'] && (
            <dl className={styles.row}>
              <dt>Рост</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['profile.height']),
                }}
              />
            </dl>
          )}
          {!!user['profile.weightCurrent'] && (
            <dl className={styles.row}>
              <dt>Текущий вес</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['profile.weightCurrent']),
                }}
              />
            </dl>
          )}
          {!!user['profile.weightTarget'] && (
            <dl className={styles.row}>
              <dt>Желаемый вес</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: getValue(user['profile.weightTarget']),
                }}
              />
            </dl>
          )}
          {!!user['profile.changeWeightTemp'] &&
            !!user['profile.weightCurrent'] &&
            !!user['profile.weightTarget'] && (
              <dl className={styles.row}>
                <dt>Изменение веса</dt>
                <dd>
                  {getChangeWeightTemp({
                    value: user['profile.changeWeightTemp'].raw,
                    currentWeight: user['profile.weightCurrent'].raw,
                    targetWeight: user['profile.weightTarget'].raw,
                  })}
                </dd>
              </dl>
            )}
          {!!user['profile.activityLevel'] && (
            <dl className={styles.row}>
              <dt>Уровень активности</dt>
              <dd>{getActivityLevel(user['profile.activityLevel'].raw)}</dd>
            </dl>
          )}
          {!!user['profile.feedSystem'] && (
            <dl className={styles.row}>
              <dt>Система питания</dt>
              <dd>{getFeedSystem(user['profile.feedSystem'].raw)}</dd>
            </dl>
          )}
          {!!user['profile.waterSystem'] && (
            <dl className={styles.row}>
              <dt>Расчет нормы воды в день</dt>
              <dd>{getWaterSystem(user['profile.waterSystem'].raw)}</dd>
            </dl>
          )}
          {!!user['profile.waterLevel'] && (
            <dl className={styles.row}>
              <dt>Норма воды в день</dt>
              <dd>{user['profile.waterLevel'].raw}</dd>
            </dl>
          )}
          {!!user['ownProducts.name'] && (
            <OwnProducts data={user['ownProducts.name']} />
          )}
          <Promocode
            documentId={user.id.raw}
            endDate={user['promoCode.endDate']}
          />
          <Entitlements
            documentId={user.id.raw}
            menuIds={menuIds}
            acquiredMenus={user.entitlements}
          />
        </li>
      ))}
    </ul>
  );
};
