import SearchLayout from '../../entities/SearchLayout';
import { useSearchUsers } from '../../requests/searchUsers/useSearchUsers';
import { UsersList } from '../../components/UsersList/ui';
import styles from './styles.module.scss';
import { useFetchMenus } from '../../requests/menu/useFetchMenus';
import { useMemo } from 'react';

export const UsersPage = () => {
  const { searchUsers, data: usersList, loading } = useSearchUsers();
  const { menusData } = useFetchMenus();

  const menuIds = useMemo(() => {
    return menusData?.map((item) => item.purchaseId);
  }, [menusData]);

  return (
    <div className={styles.pageWrapper}>
      <SearchLayout searchFunc={searchUsers} data={usersList} loading={loading}>
        {!!usersList && (
          <UsersList usersList={usersList.results} menuIds={menuIds} />
        )}
      </SearchLayout>
    </div>
  );
};
