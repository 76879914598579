import { SearchResultsItemMeta } from './search';

export enum SubscriptionKind {
  AdFree = 'AdFree',
  Royal = 'Royal',
}

export interface PromoCodeProps {
  kind: SubscriptionKind;
  endDate: number;
}

export enum FeedSystem {
  Balanced = '30/20/50',
  LowProtein = '15/20/65',
  LowFat = '40/15/45',
  LowCarbs = '65/20/15',
}

export enum ChangeWeightTemp {
  maintain = 'maintain',
  low = 'low',
  moderate = 'moderate',
  fast = 'fast',
  extraFast = 'extraFast',
}

export enum WaterLevelSystem {
  auto = 'auto',
  custom = 'custom',
}

export enum ActivityLevel {
  Non = 1,
  Average = 1.2,
  Low = 1.375,
  Middle = 1.55,
  High = 1.725,
  ExtraHigh = 1.9,
}

export interface UserSearchProps {
  id: { raw: string };
  customerId: { raw: string; snippet: string };
  historyId: { raw: string };
  firstInstallTime: { raw: number };
  'promoCode.kind'?: { raw: 'AdFree' | 'Royal'; snippet: string };
  'promoCode.endDate'?: { raw: number };
  entitlements?: { raw: string[]; snippet: string };
  'userPersonalData.email'?: { raw: string; snippet: string };
  'userPersonalData.familyName'?: { raw: string; snippet: string };
  'userPersonalData.givenName'?: { raw: string; snippet: string };
  'userPersonalData.OS'?: { raw: string; snippet: string };
  'profile.ava'?: { raw: string };
  'profile.name'?: { raw: string; snippet: string };
  'profile.gender'?: { raw: string; snippet: string };
  'profile.height'?: { raw: string; snippet: string };
  'profile.weightCurrent'?: { raw: string; snippet: string };
  'profile.weightTarget'?: { raw: string; snippet: string };
  'profile.age'?: { raw: string; snippet: string };
  'profile.activityLevel'?: { raw: ActivityLevel };
  'profile.feedSystem'?: { raw: FeedSystem };
  'profile.changeWeightTemp'?: { raw: ChangeWeightTemp };
  'profile.waterSystem'?: { raw: WaterLevelSystem };
  'profile.waterLevel'?: { raw: string };
  'ownProducts.name'?: { raw: string | string[]; snippet: string };
  favouriteProductsIds?: { raw: string[] };
  _meta: SearchResultsItemMeta;
}
