import { API, HEADERS_JSON } from '../constants';
import { EntitlementRequest, PatchPromocodeRequest } from './types';
import { ResponseCustom } from '../types';

export const patchPromocode = async ({
  documentId,
  promoCode,
}: PatchPromocodeRequest): Promise<ResponseCustom<any>> => {
  const token = localStorage.getItem('token') || '';
  const response = await fetch(`${API}/admin/patch-promocode?token=${token}`, {
    method: 'PATCH',
    headers: {
      ...HEADERS_JSON,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ documentId, promoCode }),
  });
  return await response.json();
};

export const deletePromocode = async (
  documentId: string
): Promise<ResponseCustom<any>> => {
  const token = localStorage.getItem('token') || '';
  const response = await fetch(`${API}/admin/delete-promocode?token=${token}`, {
    method: 'DELETE',
    headers: {
      ...HEADERS_JSON,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ documentId }),
  });
  return await response.json();
};

export const patchEntitlement = async ({
  documentId,
  entitlementId,
}: EntitlementRequest): Promise<ResponseCustom<any>> => {
  const token = localStorage.getItem('token') || '';
  const response = await fetch(
    `${API}/admin/patch-entitlement?token=${token}`,
    {
      method: 'PATCH',
      headers: {
        ...HEADERS_JSON,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ documentId, entitlementId }),
    }
  );
  return await response.json();
};

export const deleteEntitlement = async ({
  documentId,
  entitlementId,
}: EntitlementRequest): Promise<ResponseCustom<any>> => {
  const token = localStorage.getItem('token') || '';
  const response = await fetch(
    `${API}/admin/delete-entitlement?token=${token}`,
    {
      method: 'DELETE',
      headers: {
        ...HEADERS_JSON,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ documentId, entitlementId }),
    }
  );
  return await response.json();
};
