import { FC, ReactElement } from 'react';
import { Button } from '../../../common/shared/Button';
import { Loader } from '../../../common/shared/Loader';
import styles from './styles.module.scss';

interface Props {
  confirmHandler: () => void;
  cancelHandler: () => void;
  customText?: string | ReactElement;
  loading?: boolean;
}

export const ConfirmCard: FC<Props> = ({
  confirmHandler,
  cancelHandler,
  customText,
  loading = false,
}) => {
  return (
    <div className={styles.confirmModal}>
      <Loader visible={loading} />
      <p className={styles.text}>
        {!!customText && <>{customText}</>}
        {!customText && (
          <>
            Точно? <span className={styles.emoji}>🤨</span>
          </>
        )}
      </p>
      <div className={styles.buttonsWrapper}>
        <Button handler={confirmHandler}>
          <span>ДА</span>
        </Button>
        <Button handler={cancelHandler} outlined>
          <span>НЕТ</span>
        </Button>
      </div>
    </div>
  );
};
