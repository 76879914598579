import { MongoResponse, ResponseCustom } from '../types';
import {
  DeleteRecipeRequest,
  PatchRecipeRequest,
  PostRecipeProps,
  RecipeKeyRequest,
  RecipesCategoriesResponse,
} from './types';
import { API, API_KEY, HEADERS_JSON } from '../constants';
import { RecipeCategoriesEnum, RecipeProps } from '../../common/types/recipes';
import { ReplaceImageNameRequest } from '../app/types';

export const fetchRecipesByIds = async (
  recipeIds: string
): Promise<ResponseCustom<RecipeProps[]>> => {
  const response = await fetch(`${API}/recipes?recipeIds=${recipeIds}`, {
    method: 'GET',
    headers: {
      Authorization: `${API_KEY}`,
    },
  });
  return await response.json();
};

export const getRecipesCategories =
  async (): Promise<ResponseCustom<RecipesCategoriesResponse> | void> => {
    try {
      const response = await fetch(`${API}/recipes-categories`, {
        method: 'GET',
        headers: {
          Authorization: `${API_KEY}`,
        },
      });
      return await response.json();
    } catch {
      alert('Server error');
    }
  };

export const getRecipesByCategory = async (
  category: RecipeCategoriesEnum
): Promise<ResponseCustom<RecipeProps[]> | void> => {
  try {
    const response = await fetch(`${API}/recipes/${category}`, {
      method: 'GET',
      headers: {
        Authorization: `${API_KEY}`,
      },
    });
    return await response.json();
  } catch {
    alert('Server error');
  }
};

export const cleanRecipesImages = async (
  token: string
): Promise<ResponseCustom<string> | void> => {
  try {
    const response = await fetch(`${API}/clean-recipes-images?token=${token}`, {
      method: 'DELETE',
    });
    return await response.json();
  } catch {
    alert('Server error');
  }
};

export const replaceRecipeImageName = async ({
  itemId,
  name,
}: ReplaceImageNameRequest): Promise<ResponseCustom<string> | void> => {
  try {
    const response = await fetch(
      `${API}/recipe-replace-image?id=${itemId}&name=${name}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `${API_KEY}`,
        },
      }
    );
    return await response.json();
  } catch {
    alert('Server error');
  }
};

export const patchRecipeKey = async ({
  token,
  category,
  data,
}: RecipeKeyRequest): Promise<ResponseCustom<MongoResponse[]> | void> => {
  try {
    const response = await fetch(
      `${API}/patch-recipe-key?token=${token}&category=${category}`,
      {
        headers: HEADERS_JSON,
        method: 'PATCH',
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  } catch {
    alert('Server error');
  }
};

export const putRecipe = async ({
  recipe,
  token,
  recipeId,
}: PatchRecipeRequest): Promise<ResponseCustom<MongoResponse> | void> => {
  try {
    const response = await fetch(
      `${API}/put-recipe?id=${recipeId}&token=${token}`,
      {
        headers: HEADERS_JSON,
        method: 'PUT',
        body: JSON.stringify(recipe),
      }
    );
    return await response.json();
  } catch {
    alert('Server error');
  }
};

export const postRecipe = async ({
  recipe,
  token,
}: PostRecipeProps): Promise<ResponseCustom<MongoResponse> | void> => {
  try {
    const response = await fetch(`${API}/insert-recipe?token=${token}`, {
      headers: HEADERS_JSON,
      method: 'POST',
      body: JSON.stringify(recipe),
    });
    return await response.json();
  } catch {
    alert('Server error');
  }
};

export const deleteRecipe = async ({
  recipeId,
  token,
}: DeleteRecipeRequest): Promise<ResponseCustom<MongoResponse> | void> => {
  try {
    const response = await fetch(
      `${API}/delete-recipe?id=${recipeId}&token=${token}`,
      {
        headers: HEADERS_JSON,
        method: 'DELETE',
      }
    );
    return await response.json();
  } catch {
    alert('Server error');
  }
};
