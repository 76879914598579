import { MenusProps } from '../../common/types/menu';
import React, { FC, useState } from 'react';
import { ControlPanel } from '../ControlPanel';
import { Button } from '../../../common/shared/Button';
import { EditMenu } from './ui/EditMenu';
import { QueryObserverResult } from '@tanstack/react-query';
import { ResponseCustom } from '../../requests/types';
import { MenusOriginData } from '../../pages/Menu';
import { useDeleteMenu } from '../../requests/menu/useDeleteMenu';
import { Modal } from '../../../common/shared/Modal';
import { ConfirmCard } from '../ConfirmCard';
import styles from './ui/styles.module.scss';
import { Loader } from '../../../common/shared/Loader';
import { useFormatMenuSet } from './hooks/useFormatMenuSet';
import DisplayMenu from './ui/DisplayMenu';

interface Props {
  menuData: MenusProps;
  menusLength: number;
  loading: boolean;
  refetchMenus: () => Promise<
    QueryObserverResult<ResponseCustom<MenusProps[]>>
  >;
  menusOriginData?: MenusOriginData[];
}

export const Menu: FC<Props> = ({
  menuData,
  menusLength,
  loading,
  refetchMenus,
  menusOriginData,
}) => {
  const { menuSet, loading: formattedLoading } = useFormatMenuSet({
    menuData,
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { deleteMenu, loading: deleteLoading } = useDeleteMenu();

  const toggleEditMenu = () => {
    setShowEditMenu((prev) => !prev);
  };

  const deleteHandler = async () => {
    if (menuData) {
      await deleteMenu(menuData._id);
      await refetchMenus();
      setShowDeleteConfirm(false);
    }
  };

  const controlLeftButton = (
    <Button handler={() => setShowCreateModal(true)} blue>
      <span>Create menu</span>
    </Button>
  );

  const controlRightButton = menuData
    ? [
        <Button key="1" handler={toggleEditMenu}>
          <span>Edit</span>
        </Button>,
        <Button key="2" handler={() => setShowDeleteConfirm(true)} outlined>
          <span>Delete</span>
        </Button>,
      ]
    : [];

  return (
    <section className={styles.section}>
      <Loader visible={loading || formattedLoading} />
      <div className={styles.displayContent}>
        <DisplayMenu menuSet={menuSet} menuData={menuData} />
      </div>
      <ControlPanel
        leftButton={controlLeftButton}
        rightButtons={controlRightButton}
        showModal={showCreateModal}
        closeModal={() => setShowCreateModal(false)}
        modalChildren={
          <EditMenu
            onClose={() => setShowCreateModal(false)}
            refetchMenus={refetchMenus}
            menusLength={menusLength}
            menusOriginData={menusOriginData}
          />
        }
      />
      {showEditMenu && (
        <Modal onClose={toggleEditMenu}>
          <EditMenu
            onClose={toggleEditMenu}
            refetchMenus={refetchMenus}
            menusLength={menusLength}
            menusOriginData={menusOriginData}
            initialMenu={structuredClone(menuSet)}
            initialOrder={menuData?.order}
            initialPublic={menuData?.publicAccess}
            initialAccessType={menuData?.accessType}
            initialKaspiPrice={menuData.kaspiPrice}
            initialSubscriptionGift={menuData?.subscriptionGift}
            initialImageName={menuData?.imageName}
            initialName={menuData?.name}
            initialShortName={menuData?.shortName}
            initialWeightProfit={menuData?.weightProfit}
            initialAttachedMenuId={menuData?.attachedMenuId}
            initialDescription={menuData?.description}
            menuId={menuData?._id}
            originPurchaseId={menuData?.purchaseId}
          />
        </Modal>
      )}
      {showDeleteConfirm && (
        <Modal onClose={() => setShowDeleteConfirm(false)}>
          <ConfirmCard
            confirmHandler={deleteHandler}
            cancelHandler={() => setShowDeleteConfirm(false)}
            loading={deleteLoading}
          />
        </Modal>
      )}
    </section>
  );
};
