import { MongoResponse, ResponseCustom } from '../types';
import {
  DeleteRecipeRequest,
  PatchMenuRequest,
  PostMenuRequest,
} from './types';
import { MenusProps } from '../../common/types/menu';
import { API, API_KEY, HEADERS_JSON } from '../constants';

export const fetchMenus = async (): Promise<ResponseCustom<MenusProps[]>> => {
  const response = await fetch(`${API}/menus`, {
    method: 'GET',
    headers: {
      Authorization: `${API_KEY}`,
      ...HEADERS_JSON,
    },
  });

  if (!response.ok) {
    alert('Server error');
  }

  return await response.json();
};

export const postMenu = async ({
  token,
  data,
}: PostMenuRequest): Promise<ResponseCustom<MenusProps>> => {
  const response = await fetch(`${API}/menu`, {
    method: 'POST',
    headers: {
      ...HEADERS_JSON,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    alert('Server error');
  }

  return await response.json();
};

export const patchMenu = async ({
  _id,
  token,
  data,
}: PatchMenuRequest): Promise<ResponseCustom<MenusProps>> => {
  const body = {
    _id,
    data,
  };

  const response = await fetch(`${API}/menu`, {
    method: 'PATCH',
    headers: {
      ...HEADERS_JSON,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    alert('Server error');
  }

  return await response.json();
};

export const deleteMenu = async ({
  _id,
  token,
}: DeleteRecipeRequest): Promise<ResponseCustom<MongoResponse>> => {
  const response = await fetch(`${API}/menu?id=${_id}`, {
    method: 'DELETE',
    headers: {
      ...HEADERS_JSON,
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
};
