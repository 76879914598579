import { FC } from 'react';
import { roundDotOne } from '../../../common/utils/formatNumbers';
import styles from './styles.module.scss';

interface Props {
  proteins: number;
  fat: number;
  carbohydrates: number;
  kcal: number;
}

export const NutrientsCell: FC<Props> = ({
  proteins,
  fat,
  carbohydrates,
  kcal,
}) => {
  return (
    <>
      <div className={styles.cell}>
        <span className={styles.title}>prot</span>
        <span>{roundDotOne(proteins)}</span>
      </div>
      <div className={styles.cell}>
        <span className={styles.title}>fat</span>
        <span>{roundDotOne(fat)}</span>
      </div>
      <div className={styles.cell}>
        <span className={styles.title}>carbs</span>
        <span>{roundDotOne(carbohydrates)}</span>
      </div>
      <div className={styles.cell}>
        <span className={styles.title}>kcal</span>
        <span>{roundDotOne(kcal)}</span>
      </div>
    </>
  );
};
