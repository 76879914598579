export enum ImageType {
  recipe = 'recipe',
  menu = 'menu',
}

export interface UploadImageRequest {
  formData: FormData;
  imageType: ImageType;
  token: string;
}

export interface ReplaceImageNameRequest {
  itemId: string;
  name: string;
}
