import cn from 'classnames';
import styles from './styles.module.scss';
import { Spinner } from '../../../common/shared/SpinnerPretty';
import React, {
  ChangeEvent,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDebounce } from '../../../common/hooks/useDebounce';

import { SearchRequest, SearchResponse } from '../../common/types/search';

interface Props<T> {
  searchFunc: (requestData: SearchRequest) => any;
  children: ReactNode;
  loading: boolean;
  data?: SearchResponse<T>;
}

const SearchLayout = <T,>({
  searchFunc,
  data,
  children,
  loading,
}: Props<T>) => {
  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const debouncedSearch = useDebounce(searchFunc, 700);

  const searchRequest = useCallback(
    ({ query, page }: SearchRequest) => {
      debouncedSearch({ query, page });
    },
    [debouncedSearch]
  );

  useEffect(() => {
    searchRequest({ query: '', page: 1 });
  }, [searchRequest]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
    searchRequest({ query: value, page: 1 });
  };

  const canNextPage = data && data?.meta?.page?.total_pages > page;
  const canPrevPage = data && page > 1;

  const onNextPage = () => {
    if (canNextPage) {
      setPage((prev) => prev + 1);
      searchRequest({ query, page: page + 1 });
    }
  };

  const onPrevPage = () => {
    if (canPrevPage) {
      setPage((prev) => prev - 1);
      searchRequest({ query, page: page - 1 });
    }
  };

  return (
    <section className={cn('admin-page-wrapper', styles.section)}>
      <div className={styles.inputWrapper}>
        <div className={styles.inputHolder}>
          <input type="text" value={query} onChange={onChange} />
          {loading && (
            <div className={styles.inputSpinner}>
              <Spinner color="grayColor" size="small" />
            </div>
          )}
        </div>
        <dl className={styles.infoBlock}>
          <dt>Results:</dt>
          <dd>{data?.meta.page.total_results}</dd>
        </dl>
      </div>
      {children}
      <div className={styles.paginationBlock}>
        <button onClick={onPrevPage} disabled={!canPrevPage}>
          {'<<< prev'}
        </button>
        <div className={styles.display}>
          <span>
            <b>{data?.meta?.page?.current}</b>
          </span>
          <span>({data?.meta?.page?.total_pages})</span>
        </div>
        <button onClick={onNextPage} disabled={!canNextPage}>
          {'next >>>'}
        </button>
      </div>
    </section>
  );
};

export default memo(SearchLayout);
