import React, { FC, useMemo, useState } from 'react';
import { getYyyyMmDd } from '../../../common/utils/formatDate';
import styles from './styles.module.scss';
import cn from 'classnames';
import { InputButton } from '../../../shared/InputButton';
import { Modal } from '../../../../common/shared/Modal';
import { useForm } from 'react-hook-form';
import PatternField from '../../../../common/shared/SubjectFields/PatternField';
import { Button } from '../../../../common/shared/Button';
import { validateSubscriptionDate } from '../utils/validations';
import { getTimestampFromSubscriptionDate } from '../utils/misc';
import { usePatchPromocode } from '../../../requests/users/usePatchPromocode';
import { PromoCodeProps, SubscriptionKind } from '../../../common/types/users';
import { Loader } from '../../../../common/shared/Loader';
import { useDeletePromocode } from '../../../requests/users/useDeletePromocode';
import { ConfirmCard } from '../../ConfirmCard';

interface FormProps {
  date: string;
}

interface Props {
  documentId: string;
  endDate?: { raw: number };
}

export const Promocode: FC<Props> = ({ documentId, endDate }) => {
  const { patchPromocode, loading: patchLoading } = usePatchPromocode();
  const { deletePromocode, loading: deleteLoading } = useDeletePromocode();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  const toggleCreateModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const toggleDeleteConfirmModal = () => {
    setDeleteConfirmModalOpen((prev) => !prev);
  };

  const date = useMemo(() => {
    if (endDate) {
      return getYyyyMmDd(endDate.raw);
    }
    return '';
  }, [endDate]);

  const hasSubscription = useMemo(() => {
    if (endDate?.raw) {
      const currentDate = Date.now();
      return endDate.raw >= currentDate;
    }
    return false;
  }, [endDate]);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<FormProps>({
    mode: 'onSubmit',
  });

  const onPatchPromocode = async (formProps: FormProps) => {
    const timestamp = getTimestampFromSubscriptionDate(formProps.date);
    const promoCode: PromoCodeProps = {
      kind: SubscriptionKind.Royal,
      endDate: timestamp,
    };
    await patchPromocode({ documentId, promoCode });
    toggleCreateModal();
  };

  const onDeletePromocode = async () => {
    await deletePromocode(documentId);
    toggleDeleteConfirmModal();
  };

  return (
    <>
      <Loader visible={patchLoading || deleteLoading} />
      <dl className={styles.row}>
        <dt>Промокот</dt>
        <dd>
          {!!date && (
            <div
              className={cn(
                styles.itemWidget,
                { [styles.success]: hasSubscription },
                { [styles.inactive]: !hasSubscription }
              )}
            >
              {date}
            </div>
          )}
          <InputButton text="Назначить промокот" handler={toggleCreateModal} />
          {!!date && (
            <InputButton
              text="Удалить промокот"
              handler={toggleDeleteConfirmModal}
              alert
            />
          )}
        </dd>
      </dl>
      {createModalOpen && (
        <Modal onClose={toggleCreateModal}>
          <div className={styles.modalWrapper}>
            <p className={styles.title}>Дата окончания промокода</p>
            <form onSubmit={handleSubmit(onPatchPromocode)}>
              <PatternField
                inputClassName={styles.input}
                format="##-##-####"
                placeholder="__-__-____"
                mask="_"
                control={control}
                id="promocode-date-input"
                fieldName="date"
                label="Дата окончания"
                error={errors.date}
                validate={validateSubscriptionDate}
              />
              <div className={styles.buttonWrapper}>
                <Button type="submit">Подтвердить</Button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {deleteConfirmModalOpen && (
        <Modal onClose={toggleDeleteConfirmModal}>
          <ConfirmCard
            confirmHandler={onDeletePromocode}
            cancelHandler={toggleDeleteConfirmModal}
          />
        </Modal>
      )}
    </>
  );
};
