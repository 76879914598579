import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { replaceRecipeImageName } from './requests';
import { ResponseCustom } from '../types';
import { ReplaceImageNameRequest } from '../app/types';

type UseReplaceRecipeImageName = () => {
  replaceImageName: UseMutateAsyncFunction<
    ResponseCustom<string> | void,
    unknown,
    ReplaceImageNameRequest
  >;
  replaceLoading: boolean;
};

export const useReplaceRecipeImageName: UseReplaceRecipeImageName = () => {
  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<string> | void,
    unknown,
    ReplaceImageNameRequest
  >({
    mutationFn: ({ itemId, name }) => replaceRecipeImageName({ itemId, name }),
    onSuccess: (response) => {
      if (response?.error) {
        alert(response.error);
      }
    },
  });

  return {
    replaceImageName: mutateAsync,
    replaceLoading: isPending,
  };
};
