export const API = process.env.REACT_APP_API_ENDPOINT;
export const API_KEY = process.env.REACT_APP_API_KEY;

export const HEADERS_JSON = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const SHORT_STALE_TIME = 1000 * 60 * 5; // 5 min
export const LONG_STALE_TIME = 1000 * 60 * 60; // 1 hour

export enum QUERY_KEYS {
  FETCH_MENUS = 'FETCH_MENUS',
  PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES',
  PRODUCTS_BY_CATEGORIES = 'PRODUCTS_BY_CATEGORIES',
  RECIPES_CATEGORIES = 'RECIPES_CATEGORIES',
  USER_PRODUCTS = 'USER_PRODUCTS',
}

export const MUTATION_KEYS = {
  MUTATE_PRODUCTS_BY_IDS: 'MUTATE_PRODUCTS_BY_IDS',
  MUTATE_RECIPES_BY_IDS: 'MUTATE_RECIPES_BY_IDS',
};
